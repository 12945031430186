
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































.panel-bundles {
  width: 100vw;
  min-height: $dynamic-100vh;
  padding: 6rem 0;
  background: $white;

  @include mq(m) {
    width: 80vw;
    max-width: 110rem;
  }
}

.panel-bundles__slider-outer {
  --padding: #{$spacing * 1.5};

  padding: 0 var(--padding);

  @include mq(l) {
    --padding: #{$spacing * 3};
  }

  @include mq(xl) {
    --padding: #{$spacing * 5.6};
  }
}

.panel-bundles__title {
  @extend %text-center;
  @extend %fw-medium;

  margin-bottom: $spacing * 1.5;
  font-family: $ff-alt;

  ::v-deep strong {
    display: block;
    font-size: 2.4rem;
    line-height: 1;
  }

  @include mq(l) {
    margin-bottom: $spacing * 2;
    font-size: 2.4rem;

    ::v-deep strong {
      font-size: 4.8rem;
    }
  }
}

.panel-bundles__slider {
  ::v-deep {
    .product-slider__slider {
      --gradient-color: #{$white};
    }
  }
}
